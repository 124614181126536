<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/thingsidotrans.png')"
              max-height="40px"
              max-width="40px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              Things I Do
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Welcome
          </p>
          <p class="mb-2">
            Join us and begin to realise your potential
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form
            ref="registrationForm"
            v-model="registrationFormValid"
            lazy-validation
          >
            <v-text-field
              v-model="email"
              outlined
              label="Email Address"
              placeholder="gareth@tido.com"
              hide-details
              class="mb-3"
              :rules="emailRules"
            ></v-text-field>

            <v-text-field
              v-model="fullname"
              outlined
              label="Full Name"
              placeholder="Gareth Boucher"
              hide-details
              class="mb-3"
              :rules="nameRules"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              :rules="passwordRules"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <v-checkbox
              hide-details
              class="mt-1"
            >
              <template #label>
                <div class="d-flex align-center flex-wrap">
                  <span class="me-2">I agree to</span><a href="javascript:void(0)">privacy policy &amp; terms</a>
                </div>
              </template>
            </v-checkbox>

            <v-btn
              block
              color="primary"
              class="mt-6"
              @click="register"
            >
              Sign Up
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            Already have an account?
          </span>
          <router-link :to="{ name:'pages-login' }">
            Sign in instead
          </router-link>
        </v-card-text>

        <!-- divider -->
        <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">or</span>
          <v-divider></v-divider>
        </v-card-text>

        <!-- social link -->
        <v-card-actions class="d-flex justify-center">
          <v-btn
            v-for="link in socialLink"
            :key="link.icon"
            icon
            class="ms-1"
          >
            <v-icon :color="$vuetify.theme.dark ? link.colorInDark:link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import account from '@/library/account'

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const fullname = ref('')

    const name = computed(() => {
      const allNames = fullname.value.toString().split(' ')
      const last = allNames.pop()
      const first = allNames.shift()
      const middles = allNames.join(' ')

      return {
        firstname: first,
        middlenames: middles,
        lastname: last,
      }
    })

    const email = ref('')
    const password = ref('')
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    const emailRules = [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ]
    const passwordRules = [
      v => !!v || 'Password is required',
    ]
    const nameRules = [
      v => !!v || 'Name is required',
      v => (v || '').indexOf(' ') > -1 || 'At least two names',
    ]

    const registrationFormValid = ref(true)
    const registrationForm = ref(null)

    const register = function register() {
      const isValid = registrationForm.value.validate()

      if (isValid) {
        const newuser = {
          firstname: name.value.firstname,
          lastname: name.value.lastname,
          middlenames: name.value.middlenames,
          email: email.value,
          password: password.value,
        }

        account.register(newuser)
          .then(result => {
            if (result.registered && result.loggedIn) {
              console.log('Registration complete')
              console.log(result.paymentLink)
              window.location.href = result.paymentLink
            }
          })

        console.log('Register was called.')
      }
    }

    return {
      isPasswordVisible,
      fullname,
      email,
      password,
      socialLink,
      emailRules,
      passwordRules,
      nameRules,
      registrationFormValid,
      registrationForm,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      register,
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
